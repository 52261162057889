import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import './style.css'

function App() {
  const [value, setValue] = useState('');
  const [excavate, setExcavate] = useState(false);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [file, setFile] = useState();

  const handleChange = (e) => {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }


  return (
    <>
      <h2 className='header'>QR Generator</h2>
      <div className='container'>
        <div className='forms'>
          <div>
            <p>URL</p>
            <input type="text" value={value} onChange={(e) => setValue(e.target.value)} />
          </div>

          <div>
            <p>QR Görsel</p>
            <input type="file" onChange={handleChange} />
          </div>
          <div>
            <p>QR Görsel Genişlik</p>
            <input type="text" value={width} onChange={(e) => setWidth(e.target.value)} />
          </div>
          <div>
            <p>QR Görsel Yükseklik</p>
            <input type="text" value={height} onChange={(e) => setHeight(e.target.value)} />
          </div>
          <div className='checkbox'>
            <p>Görsel QR içine gömülü olsun mu</p>
            <input type="checkbox" checked={excavate} onChange={(e) => setExcavate(e.target.checked)} />

          </div>
        </div>
        <div className='output'>
          <QRCode className='QR' size={200} value={value} level="H" imageSettings={{ src: file, excavate: excavate, width: width, height: height }} />
        </div>
      </div>
    </>
  );
}

export default App;
